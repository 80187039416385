import React, { lazy } from "react";
// import AppDevelopmentNew from "./pages/Services/app-development-new";

// import AppDevelopment from "./pages/Services/app-development-new";
const TopBlockChainDevelopment = lazy(() => import("./pages/BlogDetails/top-blockchain-development"));
const TopTenWebsiteDesign = lazy(() => import("./pages/BlogDetails/top-ten-website-design"));
const HowToChoosWebDevelopment = lazy(() => import("./pages/BlogDetails/how-to-choose-web-development"));
const HowToChoosUIUXAgencylondon = lazy(() => import("./pages/BlogDetails/how-to-choose-the-best-ui-ux-agency-in-london-for-your-project"));
const BestWeb3Developmentcompanies = lazy(() => import("./pages/BlogDetails/best-web3-development-companies-in-london"));
const Top10datingappdevelopmentcompanies = lazy(() => import("./pages/BlogDetails/top-10-dating-app-development-companies-in-london"));
const Top10UIUXdesignagencyinlondon = lazy(() => import("./pages/BlogDetails/top-10-ui-ux-design-agency-in-london-2025"));
const Top10artificialintelligencedevelopment = lazy(() => import("./pages/BlogDetails/top-10-artificial-intelligence-development-companies-in-london"));
const TopFintechAppDevelopmentCompaniesinLondon = lazy(() => import("./pages/BlogDetails/top-10-fintech-app-development-companies-in-london"));
const Topratedcustommobileappdevelopmentcompanies = lazy(() => import("./pages/BlogDetails/top-rated-custom-mobile-app-development-companies-in-london"));
const Howtochoosethebestappdevelopmentcompany = lazy(() => import("./pages/BlogDetails/how-to-choose-the-best-app-development-company-in-london"));
const Top10appdesignandappdevelopmentcompanieslondonfor2025 = lazy(() => import("./pages/BlogDetails/top-10-app-design-and-app-development-companies-london-for-2025"));
const Thebestmobileappdeveloperslondon = lazy(() => import("./pages/BlogDetails/hire-the-best-mobile-app-developers-london"));
const Topiphoneandandroidappdevelopmentcompanieslondon = lazy(() => import("./pages/BlogDetails/top-iphone-and-android-app-development-companies-in-london"));
const Latesttrendsinmobileappdevelopmentforlondonbusinesses = lazy(() => import("./pages/BlogDetails/latest-trends-in-mobile-app-development-for-london-businesses"));
const LoomApp = lazy(() => import('./pages/Portfolio/Details/loom-app'));
const Blog = lazy(() => import('./pages/Blog'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/index.js'));

const Home = lazy(() => import("./pages/home-new"));
const About = lazy(() => import("./pages/about-new"));
const UIUXDesign = lazy(() => import("./pages/Services-new/ui-ux-design"));
// import UIUXDesign from "./pages/Services/ui-ux-design-new";
// import UIUXDesign from "./pages/Services-new/ui-ux-design-new";
// const WebDevelopment = lazy(() => import("./pages/Services/web-development"));
const WebDevelopment = lazy(() => import("./pages/Services-new/web-development"));
const Web3Development = lazy(() => import("./pages/Services-new/web3-development"));
const AppDevelopment = lazy(() => import("./pages/Services-new/app-development"));
const FintechAccounting = lazy(
  () => import("./pages/Services-new/fintech-accounting")
);
// const FintechAccountingNew = lazy(
//   () => import("./pages/Services/fintech-accounting")
// );
const CRMDevelopment = lazy(() => import("./pages/Services-new/crm-development"));
// const CRMDevelopmentNew = lazy(() => import("./pages/Services/crm-development"));
// const TestingAuditingNew = lazy(() => import("./pages/Services/testing-auditing"));
const TestingAuditing = lazy(() => import("./pages/Services-new/testing-auditing"));
const EcommerceShopify = lazy(
  () => import("./pages/Services-new/ecommerce-shopify")
);
const AIDevelopment = lazy(() => import("./pages/Services-new/ai-development.js"));
// const AIDevelopment = lazy(() => import("./pages/Services/ai-development.js"));
// const Portfolio = lazy(() => import("./pages/Portfolio"));
const Portfolio = lazy(() => import("./pages/Portfolio-new"));
const PortfolioDetails = lazy(() => import("./pages/PortfolioDetails-new"));
const PageNotFound = lazy(() => import("./components/PageNotFound"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const DataBlankPage = lazy(() => import("./components/DataBlankPage"));
const Pricing = lazy(() => import("./pages/Pricing"));
const StreaksAI = lazy(() => import("./pages/Portfolio/Details/streaks-ai"));
// const ARCC = lazy(() => import("./pages/Portfolio/Details/arcc"));
const CPay = lazy(() => import("./pages/Portfolio/Details/cpay"));
const N1Payments = lazy(() => import("./pages/Portfolio/Details/n1-payments"));
const Tippmee = lazy(() => import("./pages/Portfolio/Details/tippmee"));
const Ideon = lazy(() => import("./pages/Portfolio/Details/ideon"));

const Gather = lazy(() => import("./pages/Portfolio/Details/gather"));
const SecondDate = lazy(() => import("./pages/Portfolio/Details/second-date"));
const OneOak = lazy(() => import("./pages/Portfolio/Details/one-oak"));
const Ove = lazy(() => import("./pages/Portfolio/Details/ove-care.js"));
const FreeFree = lazy(() => import("./pages/Portfolio/Details/free-free.js"));
const Evolve = lazy(() => import("./pages/Portfolio/Details/evolve.js"));
// const EnjoyTravels = lazy(
//   () => import("./pages/Portfolio/Details/enjoy-travels")
// );
const MarketingCampaign = lazy(() => import("./pages/MarketingCampaign"));

const routes = [
  {
    path: "/",
    component: <Home />,
    exact: true
  },
  {
    path: "/blog",
    component: <Blog />,
    exact: true
  },
  {
    path: "/top-blockchain-development-companies-in-London",
    component: <TopBlockChainDevelopment />,
    exact: true
  },
  {
    path: "/top-ten-website-design-companies-in-london",
    component: <TopTenWebsiteDesign />,
    exact: true
  },
  {
    path: "/how-to-choose-a-web-development-company-in-the-UK-for-your-project",
    component: <HowToChoosWebDevelopment />,
    exact: true
  },
  {
    path: "/how-to-choose-the-best-ui-ux-agency-in-london-for-your-project",
    component: <HowToChoosUIUXAgencylondon />,
    exact: true
  },
  {
    path: "/best-web3-development-companies-in-london",
    component: <BestWeb3Developmentcompanies />,
    exact: true
  },
  {
    path: "/top-10-dating-app-development-companies-in-london",
    component: <Top10datingappdevelopmentcompanies />,
    exact: true
  },
  {
    path: "/top-10-ui-ux-design-agency-in-london-2025",
    component: <Top10UIUXdesignagencyinlondon />,
    exact: true
  },
  {
    path: "/top-10-artificial-intelligence-development-companies-in-london",
    component: <Top10artificialintelligencedevelopment />,
    exact: true
  },
  {
    path: "/top-10-fintech-app-development-companies-in-london",
    component: <TopFintechAppDevelopmentCompaniesinLondon />,
    exact: true
  },
  {
    path: "/top-rated-custom-mobile-app-development-companies-in-london",
    component: <Topratedcustommobileappdevelopmentcompanies />,
    exact: true
  },
  {
    path: "/how-to-choose-the-best-app-development-company-in-london",
    component: <Howtochoosethebestappdevelopmentcompany />,
    exact: true
  },
  {
    path: "/top-10-app-design-and-app-development-companies-london-for-2025",
    component: <Top10appdesignandappdevelopmentcompanieslondonfor2025 />,
    exact: true
  },
  {
    path: "/hire-the-best-mobile-app-developers-london",
    component: <Thebestmobileappdeveloperslondon />,
    exact: true
  },
  {
    path: "/top-iphone-and-android-app-development-companies-in-london",
    component: <Topiphoneandandroidappdevelopmentcompanieslondon />,
    exact: true
  },
  {
    path: "/latest-trends-in-mobile-app-development-for-london-businesses",
    component: <Latesttrendsinmobileappdevelopmentforlondonbusinesses />,
    exact: true
  },
  // ndon />,
  // exact: true
  // },
  {
    path: "*",
    component: <PageNotFound />,
    exact: true
  },
  {
    path: "/about",
    component: <About />,
    exact: true
  },
  {
    path: "/ui-ux-design",
    component: <UIUXDesign />,
    exact: true
  },
  {
    path: "/web3-development",
    component: <Web3Development />,
    exact: true
  },
  {
    path: "/web-development",
    component: <WebDevelopment />,
    exact: true
  },
  {
    path: "/app-development",
    component: <AppDevelopment />,
    exact: true
  },
  // {
  //   path: "/app-development-new",
  //   component: <AppDevelopmentNew />,
  //   exact: true
  // },
  {
    path: "/fintech-accounting",
    component: <FintechAccounting />,
    exact: true
  },
  // {
  //   path: "/fintech-accounting-new",
  //   component: <FintechAccountingNew />,
  //   exact: true
  // },
  {
    path: "/crm-development",
    component: <CRMDevelopment />,
    exact: true
  },
  // {
  //   path: "/crm-development-new",
  //   component: <CRMDevelopmentNew />,
  //   exact: true
  // },
  {
    path: "/testing-auditing",
    component: <TestingAuditing />,
    exact: true
  },
  // {
  //   path: "/testing-auditing-new",
  //   component: <TestingAuditingNew />,
  //   exact: true
  // },
  // {
  //   path: "/ecommerce-shopify",
  //   component: <EcommerceShopify />,
  //   exact: true
  // },
  {
    path: "/ai-development",
    component: <AIDevelopment />,
    exact: true
  },
  // {
  //   path: "/ai-development-new",
  //   component: <AIDevelopmentNew />,
  //   exact: true
  // },
  {
    path: "/portfolio",
    component: <Portfolio />,
    exact: true
  },
  // {
  //   path: "/portfolio-new",
  //   component: <PortfolioNew />,
  //   exact: true
  // },
  {
    path: "/portfolio-details",
    component: <PortfolioDetails />,
    exact: true
  },
  {
    path: "/portfolio/streaks-ai",
    component: <StreaksAI />,
    exact: true
  },
  {
    path: "/portfolio/electric-bike-system",
    component: <DataBlankPage />,
    exact: true
  },
  {
    path: "/portfolio/blockchain",
    component: <DataBlankPage />,
    exact: true
  },
  {
    path: "/portfolio/payment-gateway-system",
    component: <CPay />,
    exact: true
  },
  {
    path: "/portfolio/blog-posting-system",
    component: <DataBlankPage />,
    exact: true
  },
  {
    path: "/portfolio/ai-brainstorming-solutions",
    component: <Ideon />,
    exact: true
  },
  {
    path: "/portfolio/payment-management-system",
    component: <N1Payments />,
    exact: true
  },
  {
    path: "/portfolio/tips-management-system",
    component: <Tippmee />,
    exact: true
  },
  {
    path: "/portfolio/gather",
    component: <Gather />,
    exact: true
  },
  {
    path: "/portfolio/one-oak",
    component: <OneOak />,
    exact: true
  },
  {
    path: "/portfolio/ove-care",
    component: <Ove />,
    exact: true
  },
  {
    path: "/portfolio/free-free",
    component: <FreeFree />,
    exact: true
  },
  {
    path: "/portfolio/evolve",
    component: <Evolve />,
    exact: true
  },
  {
    path: "/portfolio/loom-app",
    component: <LoomApp />,
    exact: true
  },
  {
    path: "/portfolio/second-date",
    component: <SecondDate />,
    exact: true
  },
  {
    path: "/contact-us",
    component: <ContactUs />,
    exact: true
  },
  {
    path: "/build-your-quote",
    component: <Pricing />,
    exact: true
  },
  {
    path: "/bottlenecktool",
    component: <MarketingCampaign />,
    exact: true
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
    exact: true
  },

  // {
  //   path: "/services/ui-ux-design-new",
  //   component: <Services />,
  //   exact: true
  // }
];

export default routes;
